<template>
  <div>
    <div class="cepContact A4Container flexCloumnCenter pageBreak_no">
      <div class="form">
<!--        三、临床实践能力测评表-->
        <el-row class='flexCenter cell cell50 FS18' style='border-left: 0;'>
          三、临床实践能力测评表
        </el-row>
        <el-row class='flexStretch'>
          <el-col :span='5' class='cellMore flexCenter' style='border-left: 0;'>项&nbsp;&nbsp;目</el-col>
          <el-col :span='7'>
            <div style='width: 100%;'>
              <el-row class='cell flexCenter'>评&nbsp;&nbsp;等</el-row>
              <el-row>
                <el-col :span='6' class='cell cell50 flexCenter'>好</el-col>
                <el-col :span='6' class='cell cell50 flexCenter'>较好</el-col>
                <el-col :span='6' class='cell cell50 flexCenter'>一般</el-col>
                <el-col :span='6' class='cell cell50 flexCenter'>差</el-col>
              </el-row>
              <el-row>
                <el-col :span='6' class='cell flexCenter'>(10分)</el-col>
                <el-col :span='6' class='cell flexCenter'>(8分)</el-col>
                <el-col :span='6' class='cell flexCenter'>(6分)</el-col>
                <el-col :span='6' class='cell flexCenter'>(0分)</el-col>
              </el-row>
            </div>
          </el-col>
          <el-col :span='5' class='cellMore flexCenter'>项&nbsp;&nbsp;目</el-col>
          <el-col :span='7'>
            <div style='width: 100%;'>
              <el-row class='cell flexCenter'>评&nbsp;&nbsp;等</el-row>
              <el-row>
                <el-col :span='6' class='cell cell50 flexCenter'>好</el-col>
                <el-col :span='6' class='cell cell50 flexCenter'>较好</el-col>
                <el-col :span='6' class='cell cell50 flexCenter'>一般</el-col>
                <el-col :span='6' class='cell cell50 flexCenter'>差</el-col>
              </el-row>
              <el-row>
                <el-col :span='6' class='cell flexCenter'>(10分)</el-col>
                <el-col :span='6' class='cell flexCenter'>(8分)</el-col>
                <el-col :span='6' class='cell flexCenter'>(6分)</el-col>
                <el-col :span='6' class='cell flexCenter'>(0分)</el-col>
              </el-row>
            </div>
          </el-col>
        </el-row>
        <el-row class='ydyf' style='flex-wrap: wrap;'>
          <el-col class='nth' :span='12' v-for='(item,index) in list'>
            <el-row>
              <el-col :span='10' class='flexCenter cell bl0'>{{ item.name }}</el-col>
              <el-col :span='14'>
                <el-row>
                  <el-col :span='6' class='flexCenter cell'>{{ item.value == 10 ? '✓' : '' }}</el-col>
                  <el-col :span='6' class='flexCenter cell'>{{ item.value == 8 ? '✓' : '' }}</el-col>
                  <el-col :span='6' class='flexCenter cell'>{{ item.value == 6 ? '✓' : '' }}</el-col>
                  <el-col :span='6' class='flexCenter cell'>{{ item.value == 0 ? '✓' : '' }}</el-col>
                </el-row>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span='5' class='cell cell50 flexCenter' style='border-left: 0'>得分</el-col>
          <el-col :span='19' class='cell cell50 flexStart' style='padding-left: 10px'>{{info.evaluation.totallc}}</el-col>
        </el-row>
<!--        四、临床技能考核成绩表-->
        <el-row class='flexCenter cell cell50 FS18' style='border-left: 0;'>
          四、临床技能考核成绩表
        </el-row>
        <el-row class="">
          <el-col :span="18" class="flexCenter cell" style='border-left: 0;'>项&nbsp;&nbsp;目&nbsp;&nbsp;名&nbsp;&nbsp;称</el-col>
          <el-col :span="3" class="flexCenter cell">得&nbsp;分</el-col>
          <el-col :span="3" class="flexCenter cell">均&nbsp;分</el-col>
        </el-row>
        <el-row class='flexStretch'>
          <el-col :span='18'>
            <div style='width: 100%;'>
              <el-row class='cell flexCenter' style='border-left: 0;'>{{info.evaluation.xmname1}}</el-row>
              <el-row class='cell flexCenter' style='border-left: 0;'>{{info.evaluation.xmname2}}</el-row>
              <el-row class='cell flexCenter' style='border-left: 0;'>{{info.evaluation.xmname3}}</el-row>
            </div>
          </el-col>

          <el-col :span='3'>
            <div style='width: 100%;'>
              <el-row class='cell flexCenter'>{{info.evaluation.xmscore1}}</el-row>
              <el-row class='cell flexCenter'>{{info.evaluation.xmscore2}}</el-row>
              <el-row class='cell flexCenter'>{{info.evaluation.xmscore3}}</el-row>
            </div>
          </el-col>
          <el-col :span='3' class='cellMore flexCenter'>{{info.evaluation.xmscorepj}}</el-col>
        </el-row>
<!--        五、完整住院病例考核成绩表-->
        <el-row class='flexCenter cell cell50 FS18' style='border-left: 0;'>五、完整住院病例考核成绩表</el-row>
        <el-row class="">
          <el-col :span="18" class="flexCenter cell" style='border-left: 0;'>病&nbsp;&nbsp;历&nbsp;&nbsp;名&nbsp;&nbsp;称</el-col>
          <el-col :span="6" class="flexCenter cell">得&nbsp;分</el-col>
        </el-row>
        <el-row>
          <el-col :span='18' class="flexCenter cell" style='border-left: 0;'>{{info.evaluation.blname}}</el-col>
          <el-col :span='6' class='flexCenter cell'>{{info.evaluation.blscore}}</el-col>
        </el-row>
        <!--        六、备注栏-->
        <el-row class='flexCenter cell cell50 borderB FS18' style='border-left: 0;'>六、备注栏</el-row>
        <el-row class='borderB' style='width: 100%;height: 216px;'>
          <div class='content'
               style='width:100%;height: 100%;overflow: hidden;box-sizing: border-box;position: relative;'>
            <div :id="'divCntnrA_'+idx"
                 style='width:100%;height: 100%;overflow: hidden;'>
              <div :id="'resizeDivA_'+idx" style='width: 100%;word-break: break-all'>
                {{ info.evaluation.remark }}
              </div>
            </div>
          </div>
        </el-row>



        <el-row class="el-row flexCloumn" style='border-bottom: 0;'>
          <el-row class="cell cell50 flexStart" style='border: 0;padding-left: 10px;'>
            <div class='fontFamily_HWZS'>带教老师签名：</div>
            <img v-if='info.evaluation.sign1' :src='info.evaluation.sign1' alt=''
                 style='width: 100px;max-height: 50px;margin-top: 2px;'>
            <div v-else style='width: 100px;'></div>
          </el-row>

          <el-row class="cell50">
            <el-col :span='12' class=" cell flexStart" style='border: 0;padding-left: 10px;'>
                <div class='fontFamily_HWZS'>科主任（护士长）签名：</div>
                <img v-if='info.evaluation.sign2' :src='info.evaluation.sign2' alt=''
                     style='width: 100px;max-height: 50px;'>
                <div v-else style='width: 100px;'></div>
            </el-col>
            <el-col :span='12' class='cell  cell50' style='border: 0;'>
              <div class='fontFamily_HWZS'>科室盖章:</div>
              <div class='flex_1' style='text-align: center'> <span class='fontFamily_HWZS'>日期：</span>{{info.evaluation.signDate }}</div>
            </el-col>
          </el-row>
        </el-row>


      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Keshi2',
  data () {
    return {
      dataover: false,
      userid: '',
      info: {
        ksInfo:{},evaluation:{},identification:{},
      },
      list: [
        { name: '医疗文件书写', value: 10 }, { name: '得分规范操作能力', value: 10 },
        { name: '诊断(护理）能力', value: 10 }, { name: '无菌技术操作', value: 10 },
        { name: '观察病情能力', value: 10 }, { name: '理论联系实际', value: 10 },
        { name: '处理问题能力', value: 10 }, { name: '完成任务情况', value: 10 },
        { name: '病房管理能力', value: 10 }, { name: '独立工作能力', value: 10 }
      ]
    }
  },
  props: {
    info1: {
      type: Object,
      default: {}
    },
    idx: {
      type: Number,
      default: 1
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      let _this = this
      setTimeout(function (){
        let info = JSON.parse(JSON.stringify(_this.info1))
        // console.log(177,info)
        _this.info = { ...info }
        _this.list[0].value = info.evaluation?info.evaluation.lc1:''
        _this.list[1].value = info.evaluation?info.evaluation.lc6:''
        _this.list[2].value = info.evaluation?info.evaluation.lc2:''
        _this.list[3].value = info.evaluation?info.evaluation.lc7:''
        _this.list[4].value = info.evaluation?info.evaluation.lc3:''
        _this.list[5].value = info.evaluation?info.evaluation.lc8:''
        _this.list[6].value = info.evaluation?info.evaluation.lc4:''
        _this.list[7].value = info.evaluation?info.evaluation.lc9:''
        _this.list[8].value = info.evaluation?info.evaluation.lc5:''
        _this.list[9].value = info.evaluation?info.evaluation.lc10:''
        _this.$nextTick(() => {
          _this.resizeA()
        })
      },500)

    },
    stripscript(s) {
      var pattern = new RegExp('\n')
      var rs = ''
      for (var i = 0; i < s.length; i++) {
        rs = rs + s.substr(i, 1).replace(pattern, '')
      }
      return rs
    },
    resizeA() {
      let divHeight = document.getElementById(`divCntnrA_${this.idx}`).offsetHeight
      let textHeight = document.getElementById(`resizeDivA_${this.idx}`).offsetHeight
      // console.log('a', divHeight, textHeight)
      let i = 1
      while (textHeight > divHeight && i < 5) {
        document.getElementById(`resizeDivA_${this.idx}`).style.fontSize = `${14 - i}px`
        textHeight = document.getElementById(`resizeDivA_${this.idx}`).offsetHeight
        i++
      }
    },
    resizeB() {
      let divHeight = document.getElementById(`divCntnrB_${this.idx}`).offsetHeight
      let textHeight = document.getElementById(`resizeDivB_${this.idx}`).offsetHeight
      // console.log('b', this.idx, divHeight, textHeight)
      let i = 1
      while ((textHeight > divHeight) && i < 5) {
        document.getElementById(`resizeDivB_${this.idx}`).style.fontSize = `${14 - i}px`
        textHeight = document.getElementById(`resizeDivB_${this.idx}`).offsetHeight
        i++
      }
    }
  }
}
</script>
<style lang="less" type="text/less">
.cepContact .el-col {
  display: flex;
  padding: 0;
  margin: 0;
}

.cepContact .el-row {
  width: 100%;
  padding: 0;
  margin: 0;
}
</style>
<style scoped="scoped" type="text/less" lang="less">
@font-face {
  font-family: "华文中宋";
  src: url("/public/font/hwzs.ttf");
}

.fontFamily_HWZS {
  font-family: '华文中宋';
}
.flexStretch {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
}

.cepContact {
  font-size: 20px;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 25px 0;

  .title {
    font-size: 20px;
    line-height: 50px;
  }

  .ydyf {
    .nth:nth-of-type(2n+1) {
      .bl0 {
        border-left: 0;
      }
    }
  }

  .title {
    font-size: 20px;
    line-height: 50px;
  }

  .form {
    width: calc(100% - 50px);
    height: calc(100% - 50px);
    border: 1px solid #000;
    border-top: 0;

    div {
      font-size: 14px;
      color: #323232;
      box-sizing: border-box;
    }

    .content {
      width: 100%;
      height: 100%;
      padding: 10px;
      white-space: break-spaces;
      word-break: break-all;
      overflow: hidden;
    }

    .el-row {
      //border-top: 1px solid #000;
      //border-bottom: 1px solid #000;
    }
  }

  .col-row {
    width: 100%;
    margin: 0;
    padding: 0;
  }
}


.cepContact {

  .table {
    width: 100%;
  }

  .borderR {
    border-right: 1px solid #333;
  }

  .borderB {
    border-bottom: 1px solid #333;
  }

  .FS18 {
    font-size: 18px;
  }

  .bold {
    font-weight: 700;
    font-family: Avenir, Helvetica, Arial, sans-serif;
  }

  .cell {
    border-left: 1px solid #333;
    border-top: 1px solid #333;

    min-height: 30px;
    //line-height: 30px;
    //text-align: center;
    white-space: nowrap;
    font-size: 14px;
    font-family: "华文中宋";
    word-break: break-all;
    white-space: normal;
  }

  .cell50 {
    min-height: 50px;
    line-height: 50px;
    font-size: 14px;
    font-family: "华文中宋";
  }

  .cellMore {
    border-left: 1px solid #333;
    border-top: 1px solid #333;
    min-height: 50px;
    text-align: left;
    font-size: 14px;
    font-family: "华文中宋";
  }
}

.uline {
  text-decoration: underline;
}
</style>

